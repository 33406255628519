import styled from '@emotion/styled';
import { Flex } from '@rebass/emotion';
import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import Logo from '~images/logos/brand-awn-logo-colour-pos.svg';
import MobileHeaderLinks from '../MobileHeader/MobileHeaderLinks';
import { usePageContext } from '../../../../context/PageProvider';

const HeaderNavigation = styled.nav`
  height: 100px;
  display: flex;
  background-color: #fff;
  flex-direction: row;
  padding: 0 5vw;
  border-bottom: 1px solid #33333320;
  width: 100%;
  justify-content: space-between;
  margin: 0px auto;
  z-index: 2;

  @media (max-width: ${props => props.theme.device.sm}) {
    height: 100px;
  }

  @media (max-width: ${props => props.theme.device.xs}) {
    height: 92px;
  }
`;

const Toggle = styled(Flex)`
  /* height: 100%; */
  cursor: pointer;
`;

const VerticalMenu = styled(Flex)`
  height: calc(100% - 100px);
  background-color: #fff;
  flex-direction: column;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  left: ${props => (props.open ? '-100%' : '0')};
  transition: left 500ms ease;
  margin-top: 100px;
  overflow: scroll;
  padding-bottom: 20px;
  z-index: 99999;

  @media (max-width: ${props => props.theme.device.sm}) {
    margin-top: 100px;
  }

  @media (max-width: ${props => props.theme.device.xs}) {
    margin-top: 92px;
  }
`;

const Hamburger = styled(Flex)`
  background-color: #091e42;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? 'rotate(-45deg)' : 'inherit')};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #091e42;
    content: '';
    position: absolute;
  }

  ::before {
    transform: ${props =>
      props.open ? 'rotate(-90deg) translate(-10px, 0px)' : 'rotate(0deg)'};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? '0' : '1')};
    transform: ${props => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 10px;
  }
`;

const MobileHeader = ({ data }) => {
  const [navbarOpen, setNavbarOpen] = useState(!true);
  const { page } = usePageContext();

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = navbarOpen ? 'hidden' : 'auto';
  }, [navbarOpen]);

  const onMenuClicked = e => {
    setNavbarOpen(!navbarOpen);
  };

  const onToggleClicked = e => {
    if (e.target.getAttribute('class')?.includes('Toggle')) {
      setNavbarOpen(!navbarOpen);
    }
  };

  return (
    <>
      <HeaderNavigation>
        <Link
          to="/"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => setNavbarOpen(false)}
        >
          <img src={Logo} style={{ cursor: 'pointer', alignSelf: 'center' }} />
        </Link>

        <Toggle navbarOpen={navbarOpen} onClick={() => onToggleClicked(event)}>
          {navbarOpen ? (
            <>
              <Hamburger open onClick={() => onMenuClicked(event)} />
              <VerticalMenu>
                <MobileHeaderLinks data={data} page={page} />
              </VerticalMenu>
            </>
          ) : (
            <Hamburger onClick={() => onMenuClicked(event)} />
          )}
        </Toggle>
      </HeaderNavigation>
    </>
  );
};

export default MobileHeader;

import React from 'react';
import loadable from '@loadable/component';
import styled from '@emotion/styled';
import { Box } from 'rebass';

const checkForOriginalColorSchemaRequest = fn => props => {
  if (props.originalColorSchema) {
    return '';
  }

  return fn(props);
};

const Wrapper = styled(Box)`
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: ${props => props.width || props.size || 'auto'};
  width: ${props => props.width || props.size || 'inherit'};
  height: ${props => props.height || props.size || 'inherit'};

  svg {
    width: ${props => props.width || props.size || 'inherit'};
    height: ${props => props.height || props.size || 'inherit'};
    ${
      '' /* fill: ${checkForOriginalColorSchemaRequest(
      props => props.fill || 'transparent'
    )};
    g,
    path {
      stroke: ${checkForOriginalColorSchemaRequest(
        props => props.stroke || 'transparent'
      )};
      fill: ${checkForOriginalColorSchemaRequest(
        props => props.fill || 'transparent'
      )};
    } */
    }
  }

  svg[name='chevron-down'],
  svg[name='chevron-up'] {
    width: ${props => props.width || props.size || 'inherit'};
    height: ${props => props.height || props.size || 'inherit'};
    ${'' /* fill: ${props => props.fill || '#4a4a4a'}; */}
    ${
      '' /* g,
    path {
      stroke: ${props => props.stroke || '#4a4a4a'};
      fill: ${props => props.fill || '#4a4a4a'};
    } */
    }
  }

  &::after {
    content: attr(data-content);
    position: absolute;
    left: 21px;
    top: 50%;
    font-size: 10px;
    width: 10px;
    height: 100%;
    line-height: 5px;
  }
`;

const Icon = React.forwardRef(
  ({ name, originalColorSchema = false, clickable = false, ...props }, ref) => {
    const SVG = loadable(props => import(`../../icons/${props.name}.svg`));

    return (
      <Wrapper
        clickable={clickable}
        originalColorSchema={originalColorSchema}
        ref={ref}
        {...props}
      >
        <SVG name={name} />
      </Wrapper>
    );
  }
);

export default Icon;

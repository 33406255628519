import React from 'react';
import BaseLink from '../Elements/Link';
import { path } from 'lodash/fp';

const getPageLink = (page, pathway) => {
  let link = path('slug.current')(page);

  if (!link) {
    return null;
  }

  if (page._type === 'contentTopic') {
    link = `/explore/${page.section}/${link}`;
  }

  if (page._type === 'contentPage') {
    link = `/${pathway ? 'pathway' : 'explore'}/${page.topic.section}/${
      page.topic.slug.current
    }/${link}`;
  }

  return link.startsWith('/') ? link : `/${link}`;
};

const Link = ({
  data: { url = '', page, pathway = false, file, newTab },
  prefix = null,
  ...props
}) => {
  const pageLink = getPageLink(page, pathway);
  const fileLink = path('file.asset.url')(file);
  let to = pageLink || fileLink || url;
  if (!to) {
    console.warn(`Link component is missing link to navigate`);
    console.dir({ page, url, pageLink });
  }

  if (prefix) {
    to = prefix + to;
  }

  let openInNewTab;

  if (newTab === false) {
    openInNewTab = false;
  } else {
    openInNewTab =
      newTab || (!pageLink && (fileLink || (url && !url.startsWith('/'))));
  }

  return <BaseLink to={to} external={openInNewTab} {...props} />;
};

export default Link;

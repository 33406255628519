import breakpoints from '~lib/breakpoints';
import scrollbarWidth from './scrollbarWidth';

const colors = {
  transparent: 'rgba(255,255,255,0)',
  neutralNeutral180: '#091e42',
  neutralNeutral150: '#344563',
  neutralNeutral170: '#172b4d',
  neutralNeutral160: '#253858',
  neutralNeutral110: '#6b778c',
  neutralNeutral100: '#7a869a',
  neutralNeutral90: '#8993a4',
  neutralNeutral80: '#97a0af',
  neutralNeutral60: '#b3bac5',
  neutralNeutral70: '#a5adba',
  neutralNeutral50: '#c1c7d0',
  neutralNeutral30: '#ebecf0',
  neutralNeutral40: '#dfe1e6',
  neutralNeutral20: '#f4f5f7',
  neutralNeutral0: '#ffffff',
  neutralNeutral10: '#fafbfc',
  neutralNeutral120: '#5e6c84',
  neutralNeutral130: '#505f79',
  neutralNeutral140: '#42526e',
  blueBlue160: '#1a3092',
  blueBlue120: '#0052cc',
  blueBlue140: '#0747a6',
  blueBlue80: '#2684ff',
  blueBlue100: '#0065f1',
  blueBlue60: '#4c9aff',
  blueBlue40: '#b3d4ff',
  blueBlue20: '#deebff',
  orangeOrange160: '#f96301',
  orangeOrange120: '#ff991f',
  orangeOrange140: '#ff7b00',
  orangeOrange100: '#ffab00',
  orangeOrange60: '#ffe380',
  orangeOrange40: '#fff0b3',
  orangeOrange80: '#ffc400',
  orangeOrange20: '#fffae6',
  greenGreen140: '#006644',
  greenGreen120: '#00875a',
  greenGreen80: '#57d9a2',
  greenGreen100: '#36b37e',
  greenGreen60: '#79f2c0',
  greenGreen40: '#abf5d1',
  greenGreen20: '#e3fcee',
  purplePurple500: '#403294',
  purplePurple300: '#6554c0',
  purplePurple400: '#5243aa',
  purplePurple200: '#8777d9',
  purplePurple100: '#998dd9',
  purplePurple75: '#c0b6f2',
  purplePurple50: '#eae6ff',
  redRed120: '#de350b',
  redRed140: '#bf2600',
  redRed100: '#ff5630',
  redRed80: '#ff7452',
  redRed60: '#ff8f73',
  redRed20: '#ffebe6',
  redRed40: '#ffbdad',
  tealTeal140: '#008ca6',
  tealTeal120: '#00a2bf',
  tealTeal80: '#00c6e6',
  tealTeal100: '#00b8d9',
  tealTeal60: '#79e2f2',
  tealTeal40: '#b3f5ff',
  tealTeal50: '#e6fcff',
  primaryOrange550: '#ff7b00',
  markupCyan: '#00aeef',
  markupMagenta: '#ec008c',
  markupMagenta80: '#ff97d5',
  markupMagenta90: '#ffcae9',
  markupCyan90: '#9ae3ff',
  partnerAmazeTeal: '#97d5c9',
  partnerAmazeBlue: '#0493c9',
  partnerAmazeBlack: '#000000',
};

const fonts = {
  sans: '"Work Sans"',
  heading: '"Work Sans"',
  FeatureXL: {
    fontFamily: ' Work Sans',
    fontSize: ' 56px',
    fontWeight: ' bold',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.14',
    letterSpacing: ' -0.56px',
  },

  FeatureL: {
    fontFamily: ' Work Sans',
    fontSize: ' 48px',
    fontWeight: ' bold',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.17',
    letterSpacing: ' -0.48px',
  },
  HeadingL: {
    fontFamily: ' Work Sans',
    fontSize: ' 44px',
    fontWeight: ' bold',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.18',
    letterSpacing: ' -0.44px',
  },
  HeadingM: {
    fontFamily: ' Work Sans',
    fontSize: ' 28px',
    fontWeight: ' 600',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.29',
    letterSpacing: ' normal',
  },
  HeadingS: {
    fontFamily: ' Work Sans',
    fontSize: ' 20px',
    fontWeight: ' 600',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.4',
    letterSpacing: ' normal',
  },
  BodyIntro: {
    fontFamily: ' Work Sans',
    fontSize: ' 24px',
    fontWeight: ' normal',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 32px',
    letterSpacing: ' normal',
  },

  BodyBulletpoint: {
    fontFamily: ' Work Sans',
    fontSize: ' 16px',
    fontWeight: ' bold',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.5',
    letterSpacing: ' normal',
  },
  FooterTitle: {
    fontFamily: ' Work Sans',
    fontSize: ' 16px',
    fontWeight: ' 600',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.5',
    letterSpacing: ' normal',
  },

  BodyBold: {
    fontFamily: ' Work Sans',
    fontSize: ' 16px',
    fontWeight: ' 600',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.5',
    letterSpacing: ' normal',
  },

  BodyItalic: {
    fontFamily: ' Work Sans',
    fontSize: ' 16px',
    fontWeight: ' normal',
    fontStretch: ' normal',
    fontStyle: ' italic',
    lineHeight: ' 1.5',
    letterSpacing: ' normal',
  },

  FooterText: {
    fontFamily: ' Work Sans',
    fontSize: ' 16px',
    fontWeight: ' normal',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.5',
    letterSpacing: ' normal',
  },
  FooterTextSmall: {
    fontFamily: ' Work Sans',
    fontSize: ' 12px',
    fontWeight: ' normal',
    fontStretch: ' normal',
    fontStyle: ' normal',
    letterSpacing: ' normal',
  },
  Body: {
    fontFamily: ' Work Sans',
    fontSize: ' 16px',
    fontWeight: ' normal',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.5',
    letterSpacing: ' normal',
  },
  NavigationTextLink: {
    fontFamily: ' Work Sans',
    fontSize: ' 16px',
    fontWeight: ' normal',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.5',
    letterSpacing: ' normal',
  },
  NavigationSmallCapsLink: {
    fontFamily: ' Work Sans',
    fontSize: ' 14px',
    fontWeight: ' normal',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.14',
    letterSpacing: ' normal',
  },
  BodySmallCaps: {
    fontFamily: ' Work Sans',
    fontSize: ' 14px',
    fontWeight: ' normal',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.14',
    letterSpacing: ' normal',
  },

  FooterSmallPrint: {
    fontFamily: ' Work Sans',
    fontSize: ' 12px',
    fontWeight: ' normal',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.33',
    letterSpacing: ' normal',
  },
  BodyCaption: {
    fontFamily: ' Work Sans',
    fontSize: ' 12px',
    fontWeight: ' normal',
    fontStretch: ' normal',
    fontStyle: ' normal',
    lineHeight: ' 1.33',
    letterSpacing: ' normal',
  },
};

const buttons = {
  getStarted: {
    height: '40px',
    width: '138px',
    backgroundColor: colors.orangeOrange120,
    fontStyle: fonts.BodySmallCaps,
    borderRadius: '24px',
    border: 0,
  },
};

const spacing = {
  Xxs: '4px',
  Xs: '8px',
  S: '12px',
  M: '16px',
  L: '20px',
  Xl: '24px',
  Xxl: '28px',
  Xxl2: '32px',
  Xxl3: '36px',
  Xxl4: '40px',
  Xxl5: '44px',
  Xxl6: '48px',
  Xxl7: '52px',
  Xxl8: '56px',
  Xxl9: '60px',
  Xxl10: '64px',
  Xxl11: '68px',
  Xxl12: '72px',
  Xxl13: '76px',
  Xxl14: '80px',
  Xxl15: '84px',
  Xxl16: '88px',
  Xxl17: '92px',
  Xxl18: '96px',
  Xxl19: '100px',
  Xxl20: '104px',
};

const bps = breakpoints;
const bpsArray = Object.entries(bps).reduce((arr, bp) => {
  arr.push(bp[1]);
  arr[bp[0]] = bp[1];
  return arr;
}, []);

const scrollWidth = scrollbarWidth();

const theme = {
  loading: {
    minCardHeight: 100,
  },
  buttons: buttons,
  scrollbarWidth: scrollWidth,
  colors: colors,
  fonts: fonts,
  layout: {
    max: 1258,
  },
  zIndex: {
    navTabs: 10,
    nav: 120,
    navMobile: 100,
    menu: 110,
    burger: 120,
    timedLogout: 200,
  },
  padding: {
    xs: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    sm: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    md: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    lg: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    xl: {
      paddingLeft: 100,
      paddingRight: 100,
    },
  },
  breakpoints: bpsArray,
  device: bpsArray,
  containerWidths: {
    xs: '100%',
    sm: '100%',
    md: '100%',
    lg: '1200px',
    xl: '1200px',
  },
  fontSizes: [12, 13, 16, 21, 24, 32, 48],
  fontWeights: {
    body: 400,
    heading: 700,
    normal: 400,
    semibold: 600,
    bold: 700,
  },
};

export default theme;

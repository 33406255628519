import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from '@rebass/emotion';
import { navigate } from 'gatsby';
import React from 'react';
import ConversationSmileType1 from '~icons/conversation-smile';

const StyledCallUsMobile = styled(Box)`
  border-top: 1px solid #33333320;
  font-family: ${props => props.theme.fonts.FooterTitle};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: inherit;
  padding: 3vh 0vh 1vh 0vh;
  text-align: center;
`;

const StyledCallUsDesktop = styled(Box)`
  font-family: ${props => props.theme.fonts.FooterTitle};
  align-self: center;
  text-align: right;
`;

const NeedToTalk = styled(Box)`
  cursor: pointer;
  color: ${props => props.theme.colors.neutralNeutral180};
  align-self: center;
`;

const CallAC = styled.a`
  display: block;
  text-decoration: none;
  cursor: pointer;
  font-family: ${props => props.theme.fonts.FooterTitle};
  color: ${props => props.theme.colors.partnerAmazeBlue};
`;

const visitURL = () => {
  navigate('/autism-connect');
};

const CallUs = props => {
  const theme = useTheme();

  return props.mobile && props.mobile ? (
    <StyledCallUsMobile>
      <ConversationSmileType1
        style={{ marginTop: 5 }}
        width="23.9"
        height="22.3"
        color={theme.colors.neutralNeutral180}
      />
      <NeedToTalk>Need to talk to someone about autism?</NeedToTalk>
      <CallAC onClick={() => visitURL()}>
        Call <span style={{ textDecoration: 'underline' }}>Autism Connect</span>
      </CallAC>
      <CallAC href="tel:1300308699">1300 308 699</CallAC>
    </StyledCallUsMobile>
  ) : (
    <StyledCallUsDesktop>
      <Flex justifyContent="flex-end">
        <ConversationSmileType1
          style={{ marginRight: 5 }}
          width="23.9"
          height="22.3"
          color={theme.colors.neutralNeutral180}
        />
        <NeedToTalk style={{ marginTop: 4 }}>Need to talk?</NeedToTalk>
      </Flex>
      <CallAC onClick={() => visitURL()}>
        Call <span style={{ textDecoration: 'underline' }}>Autism Connect</span>
      </CallAC>
      <CallAC>1300 308 699</CallAC>
    </StyledCallUsDesktop>
  );
};

export default CallUs;

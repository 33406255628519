import React from 'react';

const Container = ({ row, children, ...props }) => {
  return (
    <div className="w-full flex content-center items-center">
      <div
        className={`w-full m-auto max-w-screen-xl px-5 sm:px-10 flex content-center items-center flex-${
          row ? 'row' : 'column'
        }`}
        {...props}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;

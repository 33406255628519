import styled from '@emotion/styled';
import React, { useState } from 'react';
import logo from '~images/brand-awn-logo-colour-pos.svg';
import theme from '~lib/theme';
import CallUs from './CallUs';
import { Link } from 'gatsby';
import Container from '~components/Elements/Container';

const HeaderNavigation = styled.nav`
  height: 144px;
  display: flex;
  background-color: #fff;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  z-index: 2;
  align-self: center;
  width: 100%;
  padding: 0;

  @media (max-width: ${theme.device.xl}) {
    height: 144px;
  }

  @media (max-width: ${theme.device.lg}) {
    height: 144px;
  }

  @media (max-width: ${theme.device.sm}) {
    height: 144px;
  }

  @media (max-width: ${theme.device.xs}) {
    height: 92px;
  }
`;

const HR = styled.hr`
  display: block;
  height: 1px;
  max-width: max-content;
  margin: 0;
`;

const IndexHeader = () => {
  return (
    <Container>
      <HeaderNavigation>
        <Link to="/">
          <img src={logo} />
        </Link>
        <CallUs to="/autism-connect" />
      </HeaderNavigation>
      <HR />
    </Container>
  );
};

export default IndexHeader;

import React from 'react';
import EmailIcon from '~icons/social-icons/email';
import FacebookIcon from '~icons/social-icons/facebook';
import InstagramIcon from '~icons/social-icons/instagram';
import LinkedInIcon from '~icons/social-icons/linkedin';
import TwitterIcon from '~icons/social-icons/twitter';
import YoutubeIcon from '~icons/social-icons/youtube';

function SocialIcon({
  twitter,
  facebook,
  instagram,
  youtube,
  linkedin,
  email,
}) {
  const url = twitter
    ? 'https://twitter.com/AutismAwareAus'
    : facebook
    ? 'https://www.facebook.com/AutismAwarenessAustralia/'
    : instagram
    ? 'https://www.instagram.com/autismawareaus/'
    : youtube
    ? 'https://www.youtube.com/user/AutismAwareness1'
    : linkedin
    ? 'https://www.linkedin.com/company/autism-awareness/'
    : email
    ? 'mailto:office@autismawareness.com.au'
    : null;
  return (
    <a href={url} target="_blank">
      {twitter ? (
        <TwitterIcon />
      ) : facebook ? (
        <FacebookIcon />
      ) : instagram ? (
        <InstagramIcon />
      ) : youtube ? (
        <YoutubeIcon />
      ) : linkedin ? (
        <LinkedInIcon />
      ) : email ? (
        <EmailIcon />
      ) : null}
    </a>
  );
}

export default SocialIcon;

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Flex } from '@rebass/emotion';
import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Sidebar from '~components/PageBlocks/Sidebar';
import CallUs from '../CallUs';
import { usePageContext } from '../../../../context/PageProvider';
import './MobileHeaderLinks.scss';

const isActive = path => {
  return window.location.pathname.includes(path);
};

const HeaderItemLink = styled.span`
  @media (max-width: ${props => props.theme.device.md}) {
    text-decoration: none;
    font-family: ${props => props.theme.fonts.NavigationTextLink};
    color: ${props => props.theme.colors.neutralNeutral180};
    justify-content: flex-start;
    position: relative;
    z-index: 6;
    margin: 0px 0vw;
    /* padding: 2vh 2vw 2vh 0vw; */

    :hover {
      color: ${props => props.theme.colors.blueBlue160};
      border-bottom: 4px solid ${props => props.theme.colors.orangeOrange160};
    }
  }
  ${props => props.style}
`;

const HeaderItem = ({ activePage, activeStyle, ...props }) => {
  const styles = activePage && isActive(activePage) ? activeStyle : {};
  return <HeaderItemLink {...props} style={styles} />;
};

const BackArrow = styled(Flex)`
  width: 20px;
  height: 20px;
  justify-content: flex-end;
  box-sizing: border-box;
  position: relative;
  transform: rotate(225deg);

  &::before {
    content: '';
    width: 50%;
    height: 50%;
    border-width: 0.2vmin 0.2vmin 0 0;
    border-style: solid;
    border-color: ${props => props.theme.colors.neutralNeutral180};
    display: block;
    transform-origin: 100% 0;
  }

  &:after {
    float: left;
    position: relative;
    top: -50%;
    width: 50%;
    height: 50%;
    border-width: 0 0.2vmin 0 0;
    border-style: solid;
    border-color: ${props => props.theme.colors.neutralNeutral180};
  }
`;

const FrontArrow = styled(Flex)`
  width: 20px;
  height: 20px;
  align-self: center;
  justify-content: flex-end;
  box-sizing: border-box;
  position: relative;
  transform: rotate(45deg);

  &::before {
    content: '';
    width: 50%;
    height: 50%;
    border-width: 0.2vmin 0.2vmin 0 0;
    border-style: solid;
    border-color: ${props => props.theme.colors.neutralNeutral180};
    display: block;
    transform-origin: 100% 0;
  }

  &:after {
    float: left;
    position: relative;
    top: -50%;
    width: 50%;
    height: 50%;
    border-width: 0 0.2vmin 0 0;
    border-style: solid;
    border-color: ${props => props.theme.colors.neutralNeutral180};
  }
`;

function onClick(e, item) {
  console.log(JSON.stringify(item, null, 2));
}

const MobileHeaderLinks = ({ data, page }) => {
  const theme = useTheme();
  const arrowActiveStyles = {
    color: '#1a3092 !important',
  };

  const activeStyles = {
    color: '#091e42 !important',
    borderBottom: '4px solid #f96301 !important',
  };

  const [activeMenu, setActiveMenu] = useState('main');

  const SecondaryMenuItem = props => {
    const styles = props.to && isActive(props.to) ? props.activeStyles : {};
    return props.sidebar ? (
      <div
        className="primary-menu-item-sidebar"
        onClick={() => handleOnClick(props)}
        style={styles}
      >
        {props.children}
      </div>
    ) : (
      <div
        className="primary-menu-item"
        to={{ page: props.page }}
        onClick={() => handleOnClick(props)}
        style={styles}
      >
        {props.children}
      </div>
    );
  };

  const getPageLink = page => {
    let link = page?.slug?.current;

    if (!link) {
      return null;
    }

    if (page._type === 'contentTopic') {
      link = `/explore/${page.section}/${link}`;
    }

    if (page._type === 'contentPage') {
      link = `/explore/${page.topic.section}/${page.topic.slug.current}/${link}`;
    }

    return link.startsWith('/') ? link : `/${link}`;
  };

  const handleOnClick = props => {
    if (props.goToMenu) {
      setActiveMenu(props.goToMenu);
    }
    if (props.page) {
      navigate(getPageLink(props.page));
    }
    if (props.to) {
      navigate(props.to);
    }

    return;
  };

  const PrimaryMenuItem = props => {
    return (
      <div
        data={{ page: props.page }}
        className="secondary-menu-item"
        onClick={() => handleOnClick(props)}
      >
        {props.children}
      </div>
    );
  };

  return (
    <>
      <CSSTransition
        in={activeMenu === 'main'}
        unmountOnExit
        timeout={500}
        classNames="menu-primary"
      >
        <div className="menu">
          <div>
            <PrimaryMenuItem
              to={page?.menu?.type === 'pathway' ? undefined : '/pathway'}
              goToMenu={page?.menu?.type === 'pathway' ? 'pathway' : undefined}
            >
              <HeaderItem
                goToMenu="pathway"
                to="/pathway"
                activePage="/pathway"
                activeStyle={activeStyles}
                partiallyActive={true}
              >
                {'Autism pathway'}
              </HeaderItem>
              <FrontArrow
                activeStyle={arrowActiveStyles}
                partiallyActive={true}
              ></FrontArrow>
            </PrimaryMenuItem>

            <PrimaryMenuItem
              goToMenu="children"
              activeStyle={activeStyles}
              partiallyActive={true}
            >
              <HeaderItem
                goToMenu="children"
                activeStyle={activeStyles}
                partiallyActive={true}
                activePage="/explore/children"
              >
                {'Autism in children'}
              </HeaderItem>
              <FrontArrow
                activeStyle={arrowActiveStyles}
                partiallyActive={true}
              ></FrontArrow>
            </PrimaryMenuItem>
            <PrimaryMenuItem
              goToMenu="adults"
              activeStyle={activeStyles}
              partiallyActive={true}
            >
              <HeaderItem
                goToMenu="adults"
                activeStyle={activeStyles}
                activePage="/explore/adults"
                partiallyActive={true}
              >
                {'Autism in adults'}
              </HeaderItem>
              <FrontArrow
                activeStyle={arrowActiveStyles}
                partiallyActive={true}
              ></FrontArrow>
            </PrimaryMenuItem>
          </div>
          <CallUs mobile />
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === 'pathway'}
        unmountOnExit
        timeout={500}
        classNames="menu-secondary"
      >
        <div className="menu">
          <div>
            <SecondaryMenuItem goToMenu="main">
              <BackArrow
                activeStyle={arrowActiveStyles}
                partiallyActive={true}
              ></BackArrow>
              <HeaderItem
                activeStyle={activeStyles}
                partiallyActive={true}
                style={{ textDecoration: 'underline' }}
              >
                {'Back'}
              </HeaderItem>
            </SecondaryMenuItem>
            {page && page.menu && page.article && (
              <SecondaryMenuItem sidebar>
                <Sidebar data={page.menu} page={page.article} />
              </SecondaryMenuItem>
            )}
          </div>
          <CallUs mobile />
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === 'children'}
        unmountOnExit
        timeout={500}
        classNames="menu-secondary"
      >
        <div className="menu">
          <div>
            <SecondaryMenuItem goToMenu="main">
              <BackArrow
                activeStyle={arrowActiveStyles}
                partiallyActive={true}
              ></BackArrow>
              <HeaderItem
                activeStyle={activeStyles}
                partiallyActive={true}
                style={{ textDecoration: 'underline' }}
              >
                {'Back'}
              </HeaderItem>
            </SecondaryMenuItem>

            {page &&
              page.childrenMenu &&
              page.childrenMenu.items.map(item => {
                return (
                  <SecondaryMenuItem page={item.page}>
                    <HeaderItem
                      activeStyle={activeStyles}
                      partiallyActive={true}
                      activePage={
                        item.page.section || item.page.topic
                          ? item.page.section
                            ? `/explore/${item.page.section}/${item.page.slug.current}`
                            : `/explore/${item.page.topic.section}/${item.page.topic.slug.current}/${item.page.slug.current}`
                          : item.page.slug.current
                      }
                    >
                      {item.title}
                    </HeaderItem>
                    <FrontArrow
                      activeStyle={arrowActiveStyles}
                      partiallyActive={true}
                    ></FrontArrow>
                  </SecondaryMenuItem>
                );
              })}
          </div>
          <CallUs mobile />
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === 'adults'}
        unmountOnExit
        timeout={500}
        classNames="menu-secondary"
      >
        <div className="menu">
          <div>
            <SecondaryMenuItem goToMenu="main">
              <BackArrow
                activeStyle={arrowActiveStyles}
                partiallyActive={true}
              ></BackArrow>
              <HeaderItem
                activeStyle={activeStyles}
                partiallyActive={true}
                style={{ textDecoration: 'underline' }}
              >
                {'Back'}
              </HeaderItem>
            </SecondaryMenuItem>

            {page &&
              page.adultsMenu &&
              page.adultsMenu.items.map(item => {
                return (
                  <SecondaryMenuItem
                    page={item.page}
                    activeStyle={activeStyles}
                    partiallyActive={true}
                  >
                    <HeaderItem
                      activeStyle={activeStyles}
                      activePage={
                        item.page.section || item.page.topic
                          ? item.page.section
                            ? `/explore/${item.page.section}/${item.page.slug.current}`
                            : `/explore/${item.page.topic.section}/${item.page.topic.slug.current}/${item.page.slug.current}`
                          : item.page.slug.current
                      }
                    >
                      {item.title}
                    </HeaderItem>
                    <FrontArrow
                      activeStyle={arrowActiveStyles}
                      partiallyActive={true}
                    ></FrontArrow>
                  </SecondaryMenuItem>
                );
              })}
          </div>
          <CallUs mobile />
        </div>
      </CSSTransition>
    </>
  );
};

export default MobileHeaderLinks;

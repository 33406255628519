import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import { Text as RebassText } from 'rebass';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Flex } from '@rebass/emotion';
import React, { useState, useEffect } from 'react';
import Text from '~components/Elements/Text';
import Icon from '~components/Elements/Icon';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const SidebarFlex = styled.div`
  ${props => props.theme.fonts.NavigationTextLink};
  width: 100%;

  .sidebar-list {
    position: sticky;
    top: 16px;

    &::before {
      content: '';
      position: absolute;
      left: 8px;
      top: 43px;
      width: 2px;
      height: calc(100% - 43px - 36px);
      background-color: #b3bac5;
    }
  }

  .MuiListItem-root {
    justify-content: space-between;
    width: 100%; //just for the mobile view TODO
  }

  .MuiListItem-gutters {
    padding-left: 0;
    padding-right: 0;
  }

  .MuiList-root {
    width: inherit;
  }

  @media (min-width: ${props => props.theme.device.xl}) {
    width: 360px;
  }
`;

const SidebarItemText = styled(Flex)`
  font-family: ${props => props.theme.fonts.NavigationTextLink};
  padding-top: 8px;
  padding-bottom: 8px;
`;

const SidebarItemContent = styled(Flex)`
  font-family: ${props => props.theme.fonts.NavigationTextLink};
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${props => props.theme.device.xl}) {
    width: 360px;
  }
`;

const RoundIcon = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.size === 'small' ? '16px' : '20px')};
  height: ${props => (props.size === 'small' ? '16px' : '20px')};
  border-radius: 50%;
  align-self: center;
  border-style: solid;
  border-width: 1px;
  border-color: ${props =>
    props.isComplete
      ? props.parentComplete
        ? props.theme.colors.blueBlue120
        : props.theme.colors.primaryOrange550
      : props.isCurrentPage
      ? props.parentComplete
        ? props.theme.colors.blueBlue120
        : props.theme.colors.primaryOrange550
      : props.containsCurrentPage || props.containsVisitedPage
      ? props.theme.colors.primaryOrange550
      : props.parentContains
      ? props.theme.colors.primaryOrange550
      : '#b3bac5'};
  background-color: ${props =>
    props.isComplete
      ? props.parentComplete
        ? props.theme.colors.blueBlue120
        : props.theme.colors.primaryOrange550
      : props.isCurrentPage
      ? '#fff'
      : '#fff'};
  margin-bottom: 4px;
  z-index: 100;
  margin-left: ${props => (props.size === 'small' ? '1px' : '-1px')};
`;

const checkComplete = (item, pathwayPages) => {
  let pageComplete = false;
  let itemsComplete = false;

  if (item.page && pathwayPages.includes(item.page._id)) {
    pageComplete = true;
  }

  if (item.items) {
    itemsComplete = item.items.every(item => checkComplete(item, pathwayPages));
  }

  if (item.page && item.items) {
    return pageComplete && itemsComplete;
  }

  if (item.page) {
    return pageComplete;
  }

  if (item.items) {
    return itemsComplete;
  }

  return false;
};

const checkContains = (item, page) => {
  if (item.page?._id === page?._id) {
    return true;
  }
  if (item.items) {
    return !!item.items.find(subItem => checkContains(subItem, page));
  }
  return false;
};

const checkContainsVisitedPage = (item, pathwayPages) => {
  if (item.page && pathwayPages.includes(item.page._id)) {
    return true;
  }
  if (item.items) {
    return item.items.some(subItem =>
      checkContainsVisitedPage(subItem, pathwayPages)
    );
  }
  return false;
};

const SidebarItem = ({
  depthStep = 10,
  depth = 0,
  expanded,
  item,
  isFirstItem,
  isLastItem,
  isCurrentItem,
  stage,
  isComplete,
  parentComplete,
  parentContains,
  isCurrentPage,
  containsCurrentPage,
  containsVisitedPage,
  page,
  ...rest
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const theme = useTheme();
  const { title, items, isMainItem, onClick: onClickProp } = item;

  function toggleCollapse() {
    setCollapsed(prevValue => !prevValue);
  }

  const [pathwayPages, setPathwayPages] = useLocalStorage('pathway-pages', []);

  function onClick(e) {
    if (Array.isArray(items)) {
      toggleCollapse();
    }
    if (item.page) {
      navigate(
        `/pathway/${item.page.topic.section}/${item.page.topic.slug.current}/${item.page.slug.current}`
      );
    }
  }

  useEffect(() => {
    if (isCurrentItem || containsCurrentPage) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [isCurrentItem]);

  let expandIcon;

  if (Array.isArray(items) && items.length) {
    expandIcon = !collapsed ? (
      <ExpandLessIcon
        className={
          'sidebar-item-expand-arrow' + ' sidebar-item-expand-arrow-expanded'
        }
      />
    ) : (
      <ExpandMoreIcon className="sidebar-item-expand-arrow" />
    );
  }

  return (
    <>
      <ListItem onClick={onClick} button dense {...rest}>
        <SidebarItemContent data-id="sidebar-item-content">
          <Flex flexDirection="row" justifyContent="space-between">
            <RoundIcon
              isComplete={isComplete}
              parentComplete={parentComplete}
              isCurrentPage={isCurrentPage}
              containsCurrentPage={containsCurrentPage}
              containsVisitedPage={containsVisitedPage}
              parentContains={parentContains}
              size={depth > 1 ? 'small' : 'large'}
            >
              {isComplete && parentComplete && depth <= 1 ? (
                <Icon name="stepper-check-stage-complete" size={'18px'} />
              ) : null}
              {isComplete && !parentComplete && depth <= 1 ? (
                <Icon name="stepper-check-step-complete" size={'18px'} />
              ) : null}
            </RoundIcon>
            <SidebarItemText
              style={{
                paddingLeft: depthStep + (depth == 1 || depth == 0 ? 0 : 10),
              }}
              data-id="sidebar-item-text"
            >
              {isMainItem ? (
                <Flex flexDirection="column">
                  <Text font="BodySmallCaps" color={'neutralNeutral180'} mb={0}>
                    STAGE {stage}
                  </Text>
                  <Text font={'HeadingS'} color={'neutralNeutral180'}>
                    {title}
                  </Text>
                </Flex>
              ) : (
                <RebassText
                  fontSize="16px"
                  fontWeight={isCurrentPage ? '550 !important' : 'normal'}
                >
                  {title}
                </RebassText>
              )}
            </SidebarItemText>
          </Flex>
          <Flex>{expandIcon}</Flex>
        </SidebarItemContent>
      </ListItem>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        {Array.isArray(items) ? (
          <List disablePadding dense>
            {items.map((subItem, index) => (
              <React.Fragment key={subItem._key}>
                <Divider style={{ marginLeft: '32px' }} />
                <SidebarItem
                  depth={depth + 1}
                  depthStep={depthStep}
                  item={subItem}
                  parentComplete={isComplete}
                  parentContains={containsCurrentPage || containsVisitedPage}
                  isComplete={checkComplete(subItem, pathwayPages)}
                  isCurrentPage={subItem?.page?._id === page?._id}
                  containsCurrentPage={
                    subItem.items
                      ? subItem.items.find(item => checkContains(item, page))
                      : false
                  }
                  containsVisitedPage={checkContainsVisitedPage(
                    subItem,
                    pathwayPages
                  )}
                  page={page}
                />
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  );
};

function Sidebar({ data, page, depthStep, depth, expanded }) {
  data.items.forEach(element => {
    element.isMainItem = 'true';
  });

  const [pathwayEntry, setPathwayEntry] = useLocalStorage(
    'pathway-entry',
    null
  );

  const [pathwayPages, setPathwayPages] = useLocalStorage('pathway-pages', []);

  const lastIndex = data.items.length - 1;
  const stage = data.items.findIndex(item => {
    if (item.page?._id === page?._id) {
      return true;
    }

    if (
      item.items.find(subItem => {
        if (subItem.page?._id === page?._id) {
          return true;
        }
        if (
          subItem.items?.find(subSubItem => {
            return subSubItem.page?._id === page?._id;
          })
        ) {
          return true;
        }
      })
    ) {
      return true;
    }

    return false;
  });

  return (
    <SidebarFlex>
      <List disablePadding dense className="sidebar-list">
        {data.items.map((sidebarItem, index) => (
          <React.Fragment key={sidebarItem._key}>
            {index != 0 && <Divider style={{ marginLeft: '32px' }} />}
            <SidebarItem
              depthStep={depthStep}
              depth={depth}
              expanded={expanded}
              item={sidebarItem}
              stage={index + 1}
              isFirstItem={index == 0 ? true : false}
              isLastItem={index == lastIndex ? true : false}
              isCurrentItem={index == stage}
              isComplete={checkComplete(sidebarItem, pathwayPages)}
              parentComplete={true}
              isCurrentPage={sidebarItem?.page?._id === page?._id}
              containsCurrentPage={
                sidebarItem.items
                  ? sidebarItem.items.find(item => checkContains(item, page))
                  : false
              }
              page={page}
              containsVisitedPage={checkContainsVisitedPage(
                sidebarItem,
                pathwayPages
              )}
            />
          </React.Fragment>
        ))}
      </List>
    </SidebarFlex>
  );
}

export default Sidebar;

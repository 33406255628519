import React from 'react';
import { Link } from 'gatsby';
import logo from '~images/brand-awn-logo-colour-pos.svg';
import CallUs from '../CallUs';
import DesktopHeaderLinks from './DesktopHeaderLinks';

const DesktopHeader = props => {
  return (
    <div className="sticky top-0 w-full shadow-md z-50 bg-white">
      <div className="mx-auto px-10 gap-x-8 flex flex-col md:flex-row w-full max-w-screen-xl">
        <div className="w-full flex justify-between items-center relative py-6">
          <Link to="/">
            <img src={logo} />
          </Link>
          <div className="">
            {props.page != 'Index' && <DesktopHeaderLinks data={props.data} />}
          </div>
          <CallUs to="/autism-connect" />
        </div>
      </div>
    </div>
  );
};

export default DesktopHeader;

import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { space, layout } from 'styled-system';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rewriteSanityUrl } from '~lib/sanity/sanityUtils';

const styles = props => css`
  ${space(props)};
  ${layout(props)};
`;

const StyledLink = styled(GatsbyLink)`
  ${styles}
`;

const StyledExternalLink = styled.a`
  ${styles}
`;

const Link = ({
  external,
  to,
  noStyles = false,
  target,
  className,
  children,
  ...props
}) => {
  if (external) {
    return (
      <StyledExternalLink
        className={`${className} ${
          noStyles ? 'text-black' : 'text-dark-blue underline cursor-pointer'
        }`}
        href={rewriteSanityUrl(to)}
        target={target || '_blank'}
        {...props}
      >
        {children}
      </StyledExternalLink>
    );
  }

  const filteredProps = [
    'activeClassName',
    'activeStyle',
    'partiallyActive',
    'partial',
    'onClick',
    'onHover',
    'onMouseEnter',
    'onMouseLeave',
    'onFocus',
    'onBlur',
    'to',
    'replace',
    'style',
    'css',
    'className',
    'state',
    'data-id',
    'target',
  ].reduce((obj, key) => {
    if (props.hasOwnProperty(key)) {
      obj[key] = props[key];
    }

    return obj;
  }, {});

  return (
    <StyledLink
      className={`${className} ${
        noStyles ? '' : 'text-light-blue underline cursor-pointer'
      }`}
      to={to}
      {...filteredProps}
    >
      {children}
    </StyledLink>
  );
};

export default Link;

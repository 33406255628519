import { Text as T } from '@rebass/emotion';
import { useTheme } from '@emotion/react';

const Text = ({ style = {}, font, color, children, to, ...props }) => {
  const theme = useTheme();

  return !to ? (
    <T
      style={{
        maxWidth: '816px',
        ...theme.fonts[font],
        ...{ color: theme.colors[color] },
        ...style,
      }}
      width="100%"
      mb="5px"
      {...props}
    >
      {' '}
      {children}{' '}
    </T>
  ) : (
    <T
      href={to}
      style={
        ({
          ...theme.fonts[font],
          ...{ color: `${theme.colors[color]} !important` },
          textDecoration: 'line-through !important',
          alignSelf: 'center',
          maxWidth: '816px',
          ...style,
        },
        {})
      }
      {...props}
    >
      {' '}
      {children}{' '}
    </T>
  );
};

export default Text;

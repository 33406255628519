import { css, Global } from '@emotion/react';
import { useLocation } from '@reach/router';
import React from 'react';
import 'react-dates/initialize';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@emotion/react';
import {
  isDevelopment,
  isMobile,
  isProduction,
  isRunningOnClientSide,
  isRunningOnServerSide,
} from '~lib/util';
import { BrowserContext } from '../context';
import theme from '../lib/theme';

const globalStyles = css`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }

  html,
  body {
    font-family: ${theme.fonts.sans} !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: ${theme.fonts.sans} !important;
  }

  #___gatsby,
  #___gatsby > div,
  #___gatsby > div > div {
    -webkit-overflow-scrolling: touch;
  }

  html {
    visibility: hidden;
  }

  html.wf-active {
    visibility: visible;
  }

  input,
  textarea,
  select,
  button,
  table,
  th,
  td,
  svg,
  svg text {
    font-family: ${theme.fonts.sans} !important;
  }

  strong {
    font-weight: 700;
  }

  button {
    font-weight: 600;
  }

  a {
    color: #003959;

    &:hover,
    &:focus {
      color: #007f8b;
    }
  }
  a,
  button {
    cursor: pointer;
  }

  button:disabled {
    cursor: not-allowed;
  }

  .only-xl {
    display: none !important;

    @media only screen and (min-width: 1440px) {
      display: block !important;
    }
  }

  .only-lg {
    display: none !important;

    @media only screen and (min-width: 1024px) and (max-width: 1440px) {
      display: block !important;
    }
  }

  .only-lg-xl {
    display: none !important;

    @media only screen and (min-width: 1024px) {
      display: block !important;
    }
  }

  .only-md {
    display: none !important;

    @media only screen and (min-width: 767px) and (max-width: 1024px) {
      display: block !important;
    }
  }

  .only-sm {
    display: none !important;

    @media only screen and (max-width: 767px) {
      display: block !important;
    }
  }

  .hide-mobile {
    @media only screen and (max-width: 992px) {
      display: none !important;
    }
  }

  .hide-desktop {
    @media only screen and (min-width: 992px) {
      display: none !important;
    }
  }

  .show-mobile-block {
    display: none !important;
    @media only screen and (max-width: 992px) {
      display: block !important;
    }
  }
  .show-mobile-inline {
    display: none !important;
    @media only screen and (max-width: 992px) {
      display: inline !important;
    }
  }
  .show-mobile-inline-block {
    display: none !important;
    @media only screen and (max-width: 992px) {
      display: inline-block !important;
    }
  }
  .show-mobile-flex {
    display: none !important;
    @media only screen and (max-width: 992px) {
      display: flex !important;
    }
  }

  .aria-modal-underlay {
    padding-top: 2em;
    @media screen and (max-width: 992px) {
      padding-top: 0;
    }
  }

  .full-width {
    width: 100%;
  }

  .align-h {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .aria-modal-dialog {
    background-color: #fff;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #979797;
    max-width: 960px;
    padding: 20px 30px;
    position: relative;

    @media screen and (max-width: 992px) {
      max-width: 100%;
      padding: 10px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
`;

const PreventSSR = ({ children }) => {
  return <>{isRunningOnClientSide() && children}</>;
};

const Root = ({ children, pageContext }) => {
  const location = useLocation();
  // console.log(pageContext);

  function title() {
    if (pageContext?.title) {
      return pageContext.title;
    }

    if (pageContext?.meta?.title) {
      return pageContext?.meta?.title;
    }
    return 'Autism: what next?';
  }

  function description() {
    if (pageContext?.description) {
      return pageContext.description;
    }

    if (pageContext?.meta?.description) {
      return pageContext?.meta?.description;
    }
    return '';
  }

  function keywords() {
    if (pageContext?.keywords) {
      return pageContext.keywords;
    }

    if (pageContext?.meta?.keywords) {
      return pageContext?.meta?.keywords;
    }
    return '';
  }

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title()}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
        <meta name="description" content={description()} />
        <meta name="keywords" content={keywords()} />
        {pageContext?.meta?.image && (
          <meta property="og:image" content={pageContext.meta?.image} />
        )}
        <meta
          property="og:title"
          content={pageContext?.meta?.title || 'Autism: what next?'}
        />
        <meta
          property="og:description"
          content={pageContext?.meta?.description || ''}
        />
        <meta property="og:type" content="website" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
      </Helmet>
      <Global styles={globalStyles} />

      <ThemeProvider theme={theme}>
        <BrowserContext.Provider
          value={{
            isMobile,
            isProduction,
            isDevelopment,
            isRunningOnClientSide: isRunningOnClientSide(),
            isRunningOnServerSide: isRunningOnServerSide(),
            location: location,
          }}
        >
          <PreventSSR>{children}</PreventSSR>
        </BrowserContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default Root;

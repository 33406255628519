import { useBigScreen } from '~lib/responsive';
import { usePageContext } from '../../../context/PageProvider';
import DesktopHeader from './DesktopHeader/DesktopHeader';
import IndexHeader from './IndexHeader';
import MobileHeader from './MobileHeader/MobileHeader';

const Header = props => {
  const isBigScreen = useBigScreen();
  const { page } = usePageContext();

  if (isBigScreen) {
    return <DesktopHeader {...props} />;
    // if (page?.slug?.current === 'home') {
    //   return <IndexHeader {...props} />;
    // } else {
    //   return <DesktopHeader {...props} />;
    // }
  } else {
    return <MobileHeader {...props} />;
  }
};

export default Header;

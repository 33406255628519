import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import Link from '../../Link';

const HeaderItem = styled(Link)`
  text-decoration: none;
  font-family: ${props => props.theme.fonts.NavigationTextLink};
  font-weight: 500;
  color: ${props => props.theme.colors.neutralNeutral180};
  margin: 0 1vw;
  padding-bottom: 12px;
  transition: all 100ms ease-in;
  position: relative;
  text-align: center;
  border-bottom: 4px solid transparent;

  /* :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: '.';
    color: transparent;
    background: goldenrod;
    height: 1px;
  } */

  :hover {
    color: ${props => props.theme.colors.blueBlue160};
    border-bottom: 4px solid ${props => props.theme.colors.orangeOrange160};
  }
`;

const DesktopHeaderLinks = ({ data }) => {
  const theme = useTheme();

  const activeStyles = {
    color: theme.colors.neutralNeutral180,
    borderBottom: `4px solid ${theme.colors.orangeOrange160}`,
  };

  return (
    <>
      {data.mainMenu.items.map(item => (
        <HeaderItem
          partiallyActive={true}
          activeStyle={activeStyles}
          data={item.link}
          key={item._key}
        >
          {item.title}
        </HeaderItem>
      ))}
    </>
  );
};

export default DesktopHeaderLinks;

import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import SocialIcon from '~components/Elements/SocialIcon';
import AaaMonoLogo from '~images/logos/brand-aaa-logo-colour-pos.svg';
import PartnerMonoLogo from '~images/logos/partner-austism-connect-logo-colour-pos.svg';
import Link from './Link';

const Footer = ({ data }) => {
  return (
    <>
      <div className="bg-light-blue bg-opacity-10 flex flex-col justify-center items-center py-24 px-5 space-y-8">
        <h2 className="p-0 text-center">Subscribe to our newsletter</h2>
        <p className="w-full max-w-sm text-center text-xl font-medium">
          A monthly digest of the latest news, articles and resources.
        </p>
        <GatsbyLink
          className="inline-block text-xl no-underline font-medium bg-light-blue border-2 border-light-blue px-12 py-4 transition-all text-white rounded-xl hover:bg-white hover:text-light-blue"
          to="/signup"
          onClick={() => {
            typeof window !== 'undefined' &&
              window.gtag &&
              window.gtag('event', 'conversion', {
                send_to: 'AW-299192274/Qd7MCPjI3f0CENKf1Y4B',
              });
          }}
        >
          Subscribe
        </GatsbyLink>
        <div className="flex flex-row items-center space-x-5">
          <SocialIcon twitter />
          <SocialIcon facebook />
          <SocialIcon instagram />
          <SocialIcon youtube />
          <SocialIcon linkedin />
          <SocialIcon email />
        </div>
      </div>
      <div className="bg-dark-blue px-5 pt-10 pb-5 text-white space-y-10">
        <div className="flex flex-wrap justify-between md:flex-row gap-y-7 md:gap-5 md:justify-evenly w-full md:max-w-screen-xl mx-auto">
          {data.menu.items.map(item => (
            <div
              className="flex w-[45%] md:w-auto flex-col space-y-3"
              key={item._key}
            >
              <h3 className="text-base font-bold mb-0 pb-0">{item.title}</h3>
              {item.items.map(subItem => (
                <Link
                  noStyles
                  key={subItem._key}
                  data={subItem.link}
                  className="text-white hover:text-white hover:underline"
                >
                  {subItem.title}
                </Link>
              ))}
            </div>
          ))}
          <div className="w-[45%] md:w-auto">
            <h3 className="text-base font-bold pb-4">Partners</h3>
            <div className="bg-white flex flex-col md:flex-row gap-5 p-5 md:-ml-5 rounded-md">
              <a href="https://www.autismawareness.com.au/" target="_blank">
                <img
                  src={AaaMonoLogo}
                  style={{
                    width: '160px',
                    height: '100%',
                    alignSelf: 'center',
                    overflow: 'visible',
                  }}
                />
              </a>
              <a href="https://www.amaze.org.au/" target="_blank">
                <img
                  src={PartnerMonoLogo}
                  style={{
                    width: '150px',
                    height: '100%',
                    alignSelf: 'center',
                    overflow: 'visible',
                  }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="border-t max-w-screen-xl mx-auto pt-5">
          <div className="flex flex-col md:flex-row justify-between items-baseline">
            <div className="space-x-5">
              {data.bottomMenu.items.map(item => (
                <Link
                  noStyles
                  className="text-xs text-white hover:text-white hover:underline"
                  data={item.link}
                  key={item._key}
                >
                  {item.title}
                </Link>
              ))}
            </div>
            <p className="text-xs">
              Copyright &copy; {new Date().getFullYear()} Autism: What Next? All
              rights reserved
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

import { path } from 'lodash/fp';
import React, { createContext, useContext } from 'react';

const PageContext = createContext({
  page: undefined,
});

const PageProvider = ({ children, page }) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('PageProvider', page);
  }

  return (
    <PageContext.Provider
      value={{
        page,
        isHomePage: path('slug.current')(page) === 'home',
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);

export { PageContext, PageProvider };

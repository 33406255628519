export default function ConversationSmileType1({
  style,
  width,
  height,
  color,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      stroke={color}
      style={style}
    >
      <path
        d="M19.707 6.747a9.1 9.1 0 1 0-16.285 7.891L.75 20.25l5.611-2.672a9.09 9.09 0 0 0 3.389 1.263M8.25 6.75v1.5m6-1.5v1.5"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M9.755 14a4.516 4.516 0 0 1-2.768-2.8m10.895-.7a5.344 5.344 0 0 1 4.026 8.886l.671 3.864-3.5-2.158a5.364 5.364 0 1 1-1.2-10.592Zm-2.132 4.875h0"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M15.75 15.375a.375.375 0 1 0 .375.375.375.375 0 0 0-.375-.375m3.75 0h0m0 0a.375.375 0 1 0 .375.375.375.375 0 0 0-.375-.375"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  );
}
